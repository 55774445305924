import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import accessKey from './app-test';

const SignInPage = ({ onSignIn }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [showVerification, setShowVerification] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const apiKey = accessKey;

  const validateEmail = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleSignIn = async (e) => {
    e.preventDefault();

    if (!validateEmail()) {
      setError('Invalid email address format');
      return;
    }

    try {
      setLoading(true);
      const response = await axios.post('https://api.bigbitdata.com/auth',  {
        apiKey,
        email,
        password,
        verificationCode,
      });
      
      if (response.data.success) {
        setError('');

        if (response.data.mfaRequired === 1) {
          if (showVerification) {
            navigate('/qfsapp');
          } else {
            setShowVerification(true);
          }

          setError('');
          onSignIn(response.data.userContainer, response.data.isAdmin);
          localStorage.setItem('authToken', response.data.token);
        } else {
          onSignIn(response.data.userContainer, response.data.isAdmin);
          localStorage.setItem('authToken', response.data.token);
          navigate('/qfsapp');
        }
      } else {
        if (response.data.message === 'Invalid verification code') {
          setError('Invalid Verification Code');
        } else if (response.data.message === 'Authentication failed') {
          setError('Invalid Email/Password');
        } else {
          setError('Sign-in error');
        }
      }
    } catch (error) {
      console.error('Sign-in error:', error.response?.data?.message);
      console.error('Sign-in error:', error);

      if (error.response?.data?.message === 'Invalid verification code') {
        setError('Invalid Verification Code');
      } else if (error.response?.data?.message === 'Authentication failed') {
        setError('Invalid Email/Password');
      } else {
        setError('Sign-in error');
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex items-center justify-center h-screen">
      <div className="w-1/3 p-6 bg-gray-200 rounded-md shadow-md">
        <h2 className="text-2xl font-bold mb-4">Sign In</h2>
        <form onSubmit={handleSignIn}>
          <div className="mb-4">
            <label htmlFor="email" className="block text-gray-700 font-bold mb-2">
              Email Address
            </label>
            <input
              type="email"
              id="email"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="w-full px-3 py-2 border rounded-md"
              required
            />
          </div>
          <div className="mb-4">
            <label htmlFor="password" className="block text-gray-700 font-bold mb-2">
              Password
            </label>
            <input
              type="password"
              id="password"
              name="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="w-full px-3 py-2 border rounded-md"
              required
            />
          </div>
          {showVerification && (
            <div className="mb-4">
              <p className="text-green-500 mb-2">A verification code has been emailed to you.</p>
              <label htmlFor="verificationCode" className="block text-gray-700 font-bold mb-2">
                Verification Code
              </label>
              <input
                type="text"
                id="verificationCode"
                name="verificationCode"
                value={verificationCode}
                onChange={(e) => setVerificationCode(e.target.value)}
                className="w-full px-3 py-2 border rounded-md"
                required
              />
            </div>
          )}
          {error && <p className="text-red-500 mb-4">{error}</p>}
          <button type="submit" className="bg-blue-500 text-white px-4 py-2 rounded-md" disabled={loading}>
            {loading ? 'Signing In...' : 'Sign In'}
          </button>
        </form>
      </div>
    </div>
  );
};

export default SignInPage;
