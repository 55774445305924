import React, { useState, useEffect } from 'react';
import axios from 'axios';
import accessKey from './app-test';

const AdminPage = () => {
  const [users, setUsers] = useState([]);
  const [registrations, setRegistrations] = useState([]);
  const apiKey = accessKey;
  
  useEffect(() => {
    const fetchUserData = async () => {
      await fetchUsers();
      await fetchRegistrations();
    };
  
    fetchUserData();
  // eslint-disable-next-line
  }, []);

  const fetchUsers = async () => {
    try {
      const response = await axios.post('https://api.bigbitdata.com/users', { apiKey  });
      setUsers(response.data);
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };

  const fetchRegistrations = async () => {
    try {
      const response = await axios.post('https://api.bigbitdata.com/registration', { apiKey });
      setRegistrations(response.data);
    } catch (error) {
      console.error('Error fetching registrations:', error);
    }
  };

  const deleteUser = async (id) => {
    try {
      await axios.post('https://api.bigbitdata.com/deleteUser', { id, apiKey });
      fetchUsers(); // Refresh registrations after addition
    } catch (error) {
      console.error('Error deleting User:', error);
    }
  };

  const updateUserContainer = async (userId, newContainer) => {
    try {
      await axios.post('https://api.bigbitdata.com/updateContainer', { userId, newContainer, apiKey });
      fetchUsers(); // Refresh registrations after addition
    } catch (error) {
      console.error('Error updating container:', error);
    }
  };

  const updateMFARequired = async (userId, newMFARequired) => {
    if (newMFARequired !== null) { 
    try {
      await axios.post('https://api.bigbitdata.com/updateMfa', { userId, newMFARequired, apiKey });
      fetchUsers(); // Refresh registrations after addition
    } catch (error) {
      console.error('Error updating mfa:', error);
    }
  }
  };

  const addRegistrationEmail = async (email) => {
    try {
      await axios.post('https://api.bigbitdata.com/addEmail', { email, apiKey });
      fetchRegistrations(); // Refresh registrations after addition
    } catch (error) {
      console.error('Error adding registration email:', error);
    }
  };

  const deleteRegistrationEmail = async (id) => {
    try {
      await axios.post('https://api.bigbitdata.com/deleteEmail', { id, apiKey });
      fetchRegistrations(); // Refresh registrations after addition
    } catch (error) {
      console.error('Error adding registration email:', error);
    }
  };

  return (
    <div className="p-8">
      <section className="mb-8">
        <h2 className="text-2xl font-bold mb-4">User Management</h2>
        <table className="min-w-full border border-gray-300 bg-white shadow-md rounded-lg overflow-hidden">
          <thead className="bg-gray-100">
            <tr>
              <th className="border-b p-4">ID</th>
              <th className="border-b p-4">Email</th>
              <th className="border-b p-4">User Container</th>
              <th className="border-b p-4">MFA Required</th>
              <th className="border-b p-4">Actions</th>
            </tr>
          </thead>
          <tbody>
            {Array.isArray(users) &&
              users.map((user) => (
                <tr key={user.id} className="text-center">
                  <td className="border-b p-4">{user.id}</td>
                  <td className="border-b p-4">{user.email}</td>
                  <td className="border-b p-4">{user.user_container}</td>
                  <td className="border-b p-4">{user.mfa_required}</td>
                  <td className="border-b p-4">
                    <button className="mr-2 px-4 py-2 bg-red-500 text-white rounded" onClick={() => deleteUser(user.id)}>
                      Delete
                    </button>
                    <button
                      className="mr-2 px-4 py-2 bg-blue-500 text-white rounded"
                      onClick={() => updateUserContainer(user.id, prompt('Enter new user container:'))}
                    >
                      Change Container
                    </button>
                    <button
                      className="px-4 py-2 bg-green-500 text-white rounded"
                      onClick={() => updateMFARequired(user.id, prompt('Enter new MFA required (0 or 1):'))}
                    >
                      Change MFA Required
                    </button>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </section>

      <section>
        <h2 className="text-2xl font-bold mb-4">Registration Emails</h2>
        <table className="min-w-full border border-gray-300 bg-white shadow-md rounded-lg overflow-hidden">
          <thead className="bg-gray-100">
            <tr>
              <th className="border-b p-4">Email</th>
              <th className="border-b p-4">Actions</th>
            </tr>
          </thead>
          <tbody>
            {Array.isArray(registrations) &&
              registrations.map((registerUser) => (
                <tr key={registerUser.id} className="text-center">
                  <td className="border-b p-4">{registerUser.email}</td>
                  <td className="border-b p-4">
                    <button className="px-4 py-2 bg-red-500 text-white rounded" onClick={() => deleteRegistrationEmail(registerUser.id)}>
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>

        <div className="mt-4">
          <h3 className="text-lg font-bold mb-2">Add Registration Email</h3>
          <button className="px-4 py-2 bg-blue-500 text-white rounded" onClick={() => addRegistrationEmail(prompt('Enter new registration email:'))}>
            Add Email
          </button>
        </div>
      </section>
    </div>
  );
};

export default AdminPage;