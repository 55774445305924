import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import accessKey from './app-test';


const QFSApp = ({ initialUserContainer, onSignOut, isAdmin }) => {
  const [files, setFiles] = useState([]);
  const [successMessage, setSuccessMessage] = useState('');
  const [failMessage, setFailMessage] = useState('');
  const [uploading, setUploading] = useState(false);
  const [showAdmin, setShowAdmin] = useState(false); // State to toggle admin section
  const navigate = useNavigate();
  const isAuthenticated = !!localStorage.getItem('authToken');
  const apiKey = accessKey;
  // Initialize userContainer with the stored value from localStorage
 const userContainer = initialUserContainer || localStorage.getItem('userContainer');

  useEffect(() => {
    // Check if the authentication token is present in localStorage
    
    if (!isAuthenticated) {
      // Redirect to sign-in page if not authenticated
      navigate('/signin');
    }

    // Check if the user is an admin
    if (localStorage.getItem('isAdmin') === 'true') {
      setShowAdmin(true);
    }

    // Store the userContainer in localStorage
    localStorage.setItem('userContainer', userContainer);

  }, [isAuthenticated, navigate, isAdmin, userContainer]);

  const handleDrop = (e) => {
    e.preventDefault();
    setSuccessMessage('');
    setFailMessage('');
    const droppedFiles = Array.from(e.dataTransfer.files);
    setFiles([...files, ...droppedFiles]);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleAdminClick = () => {
    // Navigate to the AdminPage when the Admin button is clicked
    navigate('/admin');
  };

  const handleRemoveFile = (index) => {
    const updatedFiles = [...files];
    updatedFiles.splice(index, 1);
    setFiles(updatedFiles);
  };

  const handleFileSelect = (e) => {
    e.preventDefault();
    setSuccessMessage('');
    setFailMessage('');
    const selectedFiles = Array.from(e.target.files);
    setFiles([...files, ...selectedFiles]);
  };
  const handleUpload = async () => {
    try {
      setUploading(true);
      const fileContents = await Promise.all(files.map(async file => {
        const content = await readFileAsBase64(file);
        return {
          name: file.name,
          content,
          type: file.type,
        };
      }));
      // Post the files to the server
      const response = await axios.post(
        'https://api.bigbitdata.com/upload',
        { files: fileContents, userContainer, apiKey }, // Include userContainer in the request
        { headers: { 'Content-Type': 'application/json' } }
      );

      if (response.status === 200) {
        setFiles([]);
        setSuccessMessage('Files uploaded successfully');
      } else {
        console.error('Server response indicates failure:', response.data);
        setFiles([]);
        setFailMessage('Files uploaded Fail');
      }
    } catch (error) {
      console.error('Error', error);
      setFiles([]);
      setFailMessage('Files uploaded Fail');
    } finally {
      setUploading(false);
    }
  };

  const readFileAsBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result.split(',')[1]);
      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(file);
    });
  };

  const handleLogout = () => {
    // Call the onSignOut callback to handle sign-out logic
    onSignOut();
    // Redirect to the sign-in page
    navigate('/signin');
  };

  return (
    <div className="flex h-screen">
      <div className="flex-1 flex flex-col">
        <header className="bg-gray-700 p-4 flex justify-between items-center">
          <h1 className="text-white text-2xl font-bold">QFS Data Share</h1>
          <div className="flex items-center">
          {showAdmin && (
              <button className="text-white mr-4" onClick={handleAdminClick}>
                Admin
              </button>
            )}
            <button className="text-white" onClick={handleLogout}>
              Logout
            </button>
          </div>
        </header>
        <div className="flex-1 flex">
          <div className="w-1/3 bg-gray-200 p-4">
            <h2 className="text-xl font-bold mb-2">Selected Files:</h2>
            <ul className="list-disc pl-4">
              {files.map((file, index) => (
                <li key={index} className="flex items-center justify-between bg-white p-2 mb-2 rounded shadow-md">
                  <span className="truncate">{file.name}</span>
                  <button className="text-red-500 hover:text-red-700 text-lg font-bold" onClick={() => handleRemoveFile(index)}>
                    X
                  </button>
                </li>
              ))}
            </ul>
            <button className="bg-blue-500 text-white py-2 px-4 rounded mt-4" onClick={handleUpload} disabled={files.length === 0}>
              Upload Files
            </button>

            {successMessage && <div className="mt-4 text-green-600 font-bold">{successMessage}</div>}
            {failMessage && <div className="mt-4 text-red-600 font-bold">{failMessage}</div>}
          </div>

          <div className="w-2/3 p-4 flex items-center justify-center">
            <div className="drop-zone border-2 border-dashed border-gray-400 p-8 text-center cursor-pointer h-full w-full" onDrop={handleDrop} onDragOver={handleDragOver}>
              <div className="flex items-center justify-between mb-4">
                <button className="bg-blue-500 text-white py-2 px-4 rounded" onClick={() => document.getElementById('fileInput').click()}>
                  Select Files
                </button>
                <input type="file" id="fileInput" multiple style={{ display: 'none' }} onChange={(e) => handleFileSelect(e)} />
              </div>

              <div className="flex items-center justify-center h-full">
                <p className="font-bold text-2xl text-gray-500">
                  Drag and drop files here
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {uploading && (
        <div className="fixed top-0 left-0 w-screen h-screen flex items-center justify-center bg-black bg-opacity-50">
          <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-blue-500"></div>
        </div>
      )}
    </div>
  );
};

export default QFSApp;